import Swiper from "swiper/dist/js/swiper.js";
// import Swiper from "swiper/bundle";
import "swiper/dist/css/swiper.css";
// import "swiper/swiper.min.css";
const initializeStoreGallery = () => {
  var storeGallery = new Swiper('.store-gallery .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 10,
    autoplay: {
      delay: 1000,
    },
    breakpoints: {
      // when window width is <= 320px
      767: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      // when window width is <= 480px
      990: {
        slidesPerView: 2,
        spaceBetween: 10
      }
    },
    loop: true
  });
}
const common = () => {
  $(window).load(function () {
    $("body").removeClass("fade-out");

    $(window).on("beforeunload", function () {
      $("body").addClass("fade-out-anim");
    });
  });

  $("#scroll-to-top").each(function () {
    $(this).click(function () {
      $("html,body").animate({ scrollTop: 0 }, "slow");
      return false;
    });
  });

  $(".top-dealers").click(function () {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("#dealer-filter").offset().top,
      },
      1000
    );
  });

  if ($('body.page-template-template-stores').length > 0) {
    initializeStoreGallery();
  } else if ($('body.saac-type').length > 0) {
    new Swiper('.swiper-container', {
      speed: 1000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      autoplay: {
        delay: 6500,
      },
    });
  } else {
    new Swiper(".swiper-container", {
      speed: 1000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 6500,
      },
    });
  }

  if ($('.templemans-slideshow_swiper_container').length > 0) {
    const templemansSlideshow = new Swiper('.templemans-slideshow_swiper', {
      speed: 1000,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.templemans-slideshow_swiper_next',
        prevEl: '.templemans-slideshow_swiper_prev',
      },
      // loop: true,
      autoplay: {
        delay: 6500,
        disableOnInteraction: false,
      },
      scrollbar: {
        el: '.templemans-slideshow_swiper_scrollbar',
        draggable: false
      }
    });

    templemansSlideshow.on('slideChange', function () {
      const currentSlide = `00${this.realIndex + 1}`.slice(-2);
      const totalSlides = `00${this.slides.length}`.slice(-2);
      $('.templemans-slideshow_swiper_slide-count').text(
        `${currentSlide} / ${totalSlides}`,
      );
    });
  }

  // new Swiper ('.cat-swiper-container', {
  //     direction: 'horizontal',
  //     loop: true
  // })

  if ($("#orderNoticeBanner").length > 0) {
    $("#orderNoticeBanner").modal({
      show: true,
    });
  }

  // Hero Banner text swap on hover of cards (content-portal-new.php)

  // function dynamically adds an img or video src to created elements depending on the data type

  const heroBanner = $("#hero-banner");
  const defaultText = heroBanner.text();

  const animationDuration = 500;
  let fadeOutTimeout;
  let currentMediaElement; // Track the current media element

  function handleHover(element, media) {
    $(element).hover(
      function () {
        clearTimeout(fadeOutTimeout); // Clear any existing fade-out timeout

        const newContent = $(this).data("content");
        const mediaType = $(this).data("type");

        // Create new media element
        let newMediaElement;
        if (mediaType === "image") {
          newMediaElement = $("<img>", {
            src: newContent,
            class: "media-content hidden",
          });
        } else if (mediaType === "video") {
          newMediaElement = $("<video>", {
            src: newContent,
            class: "media-content hidden",
            autoplay: true,
            muted: true,
          });
        }

        // Fade out current media element if present
        if (currentMediaElement) {
          currentMediaElement.fadeOut(animationDuration, function () {
            $(this).remove();
          });
        }

        // Append and fade in new media element
        media.append(newMediaElement);
        newMediaElement.fadeIn(animationDuration);
        currentMediaElement = newMediaElement;

        // Update banner text and background color
        heroBanner.text($(this).data("text")).css({
          backgroundColor: $(this).data("banner"),
          transition: "",
        });
      },
      function () {
        heroBanner.text(defaultText).css({
          backgroundColor: "transparent",
        });

        // Fade out and remove current media element
        if (currentMediaElement) {
          currentMediaElement.fadeOut(animationDuration, function () {
            $(this).remove();
          });
          currentMediaElement = null;
        }
      }
    );
  }

  const mediaWrapper = $(".media-wrapper");

  $(".saac-card").each(function () {
    handleHover(this, mediaWrapper);
  });

  $('.toggle-mobile-menu').click(function () {
    // Check if mobile search is open and close it
    if ($('.mobile-search').hasClass('open')) {
      $('.mobile-search').removeClass('open');
      $('.toggle-mobile-search span').text('Search');
    }

    $('.mobile-menu').toggleClass('open');

    // Change toggle button's span text to reflect state
    const isOpen = $('.mobile-menu').hasClass('open');
    const toggleText = isOpen ? 'Close' : 'Menu';
    $('.toggle-mobile-menu span').text(toggleText);
  });

  $('.toggle-mobile-search').click(function () {
    // Check if mobile menu is open and close it
    if ($('.mobile-menu').hasClass('open')) {
      $('.mobile-menu').removeClass('open');
      $('.toggle-mobile-menu span').text('Menu');
    }

    $('.mobile-search').toggleClass('open');

    // Change toggle button's span text to reflect state
    const isOpen = $('.mobile-search').hasClass('open');
    const toggleText = isOpen ? 'Close' : 'Search';
    $('.toggle-mobile-search span').text(toggleText);
  });
};

export default common;
