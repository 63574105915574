const findUsMap = () => {
    let lat = $('#map').attr('data-lat');
    let lng = $('#map').attr('data-lng');

    let uluru = {lat: parseFloat(lat), lng: parseFloat(lng)};

    let map1 = new google.maps.Map(document.getElementById('map'), {
        zoom: 16,
        center: uluru,
        scrollwheel: false,
    });
    let marker = new google.maps.Marker({
        position: uluru,
        map: map1,
    });
};

export default findUsMap;

