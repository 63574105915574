const locationMap = () => {

    const icon = $('#map').attr('data-pin');
    let markers = $('#map').find('.marker');

    let map = new google.maps.Map(document.getElementById('map'), {
        center: {
            lat: parseFloat($(markers[0]).attr('data-lat')),
            lng: parseFloat($(markers[0]).attr('data-lng'))
        },
        zoom: 8,
        scrollwheel: false
    });

    markers.each(function () {
        let latlng = new google.maps.LatLng(
            parseFloat($(this).attr('data-lat')),
            parseFloat($(this).attr('data-lng'))
        );

        let marker = new google.maps.Marker({
            position: latlng,
            icon: icon,
            map: map
        });
    });
};

export default locationMap;
