import 'bootstrap';
import '@fortawesome/fontawesome';
import '@fortawesome/fontawesome-pro';
import '@fortawesome/fontawesome-pro-solid';
import '@fortawesome/fontawesome-pro-regular';
import '@fortawesome/fontawesome-pro-light';
import '@fortawesome/fontawesome-free-brands';

import locationMap from './parts/location-maps';
import findUsMap from './parts/how-to-find-us-map';
import singleProduct from './parts/single-product';
import popup from './parts/popup';
import common from './common';

/*
    Basic Event Routing
    -------------------
    Switch on body class that gets applied either due to page, post type or page template
    Replace '-' in the class name with '_'
*/
const route = className => {
    switch (className) {
        case 'template_locations':
            locationMap();
            break;
        case 'template_stores':
            locationMap();
            break;
        case 'template_cafe_circa_find_us':
            findUsMap();
            break;
        case 'single_product':
            singleProduct();
    }
};

// Fire events once document has loaded
document.addEventListener('DOMContentLoaded', () => {
    common();     
    popup();                                               // Run all common code once
    const bodyClasses = document.body.className             // Get all body classes
        .replace(/-/g, '_')                                 // Replace dashes with underscores
        .replace(/page_template_/g, '')                     // Remove prefix 'page_template' from template classes
        .split(/\s+/);                                      // Split on whitespace

    for (const value of bodyClasses) {
        route(value);                                       // Run javascript for each body class
    }
});
