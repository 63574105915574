const singleProduct = () => {
    
    $('.product-buy-now').hide();
    $(window).scroll(showBuyNow);

    function showBuyNow()
    {
        var pos = $(document).scrollTop();
        var mark = $('.entry-summary').position().top;

        if(pos > mark){
            $('.product-buy-now').fadeIn();
        }
        else{
            $('.product-buy-now').fadeOut();
        }
    }

    $('.product-buy-now a.buy-now').click(function(){

        if($('.product-type-variable').length > 0){
            //variation
            var pos = $('table.variations').position().top + 130;
            $('html,body').animate({ scrollTop: pos }, 'slow');
            return false;
        }else{
            //single product
            $('.single_add_to_cart_button').click();
            return false;
        }

    });

      //Show product modal after add to cart
    if($('#additionalProductModal').length == true){
        $('#additionalProductModal').modal('show');
    }

};

export default singleProduct;
